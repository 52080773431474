<template>
<div>

  <div class="home flex justify-center m-4">

    <v-img alt="Vue logo" contain max-height="200" src="@/assets/lcs_logo.jpg"></v-img>

  </div>

  <div class="text-center">
    <h2 class="text-6xl">Experience Crafting</h2>
  </div>

  <div class="p-8 text-justify">

    <h2 class="text-2xl text-center font-bold">We do not work on projects, we do not develop apps, we craft experiences.</h2>
    
    <p>
      When we craft an experience what we only think about is the soul of it, it's core, it's value, it's purpose, then we decide what are the most suitable technologies that we would use, maybe it is as simple as a facebook page or as complex as a suite of cross platform apps. For us we do not see products and users, what we see is humans and technology living in perfect symbiosis.
    </p>
    <p>
      Right now our current experience is a self development ecosystem superSati, other projects in the pipeline are a game and a next gen communication app, so yes we do not have a defined field, we follow our passions wherever they might lead us. 
    </p>
    <p>
      Each experience is personal to us! It is the result of deep ingrained passions and personal experiences.
    </p>

    <h2 class="text-2xl text-center font-bold">Our Core Beliefs & Values</h2>
    <p>
      We believe in making the world a better place by crafting experiences that enhances our quality of life, our ways of communication, or even simply our entertainment. 
    </p>
    <p>
      We believe in humaine technology. Technology should enhance our quality of life and help us reach higher selves and not monetize us, our time and our attention.
    </p>
    <p>
      We believe that our work and our lives are not two separate entities, it is part of our souls and our souls are poured into it.
    </p>
    <p>
      We believe in open collaboration, accidental interactions, random creativity. We oscilate between up in the air "l'art pour l'art" & strict overzealous self discipline.
    </p>

    <h2 class="text-2xl text-center font-bold">Amr El Fouly</h2>
    <h3 class="text-xl text-center font-bold">Founder & CEO</h3>
  </div>
</div>
</template>

<script>

export default {
  name: 'AboutUs',
}
</script>
